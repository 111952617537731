import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Menu,
  MenuButton,
  MenuList,
  RadioGroup,
  Stack,
  Text,
  Button,
  MenuItem,
  Radio,
  Show
} from '@chakra-ui/react'
import { useAccounts } from '@/hooks/polkadotExtension/useAccounts'

export const ConnectPolkadotJS = () => {
  const { accounts, account, setAccount } = useAccounts()
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        bg="whiteAlpha.200"
        fontSize={{ base: 12, lg: 16 }}
        _hover={{
          bg: 'whiteAlpha.200'
        }}
        _active={{
          bg: 'whiteAlpha.200'
        }}
      >
        {account ? (
          <Text>
            {account.meta.name}: {account.address.slice(0, 7)}...
          </Text>
        ) : (
          <Text>connect wallet</Text>
        )}
      </MenuButton>
      <MenuList bg="whiteAlpha.200" border="none">
        <RadioGroup
          onChange={(v) => {
            const anct = accounts?.find((x) => x.address === v)
            if (anct) {
              setAccount(anct)
            }
          }}
          value={account?.address}
        >
          <Stack>
            {accounts?.map((acnt, i) => {
              return (
                <MenuItem key={i} bg="whiteAlpha.200">
                  <Radio
                    key={acnt.address}
                    value={acnt.address}
                    checked={acnt.address === account?.address}
                  >
                    <Show above="lg">
                      <Text fontSize={16}>
                        {acnt.meta.name}: {acnt.address}
                      </Text>
                    </Show>
                    <Show below="lg">
                      <Text fontSize={12}>
                        {acnt.meta.name}: {acnt.address.slice(0, 15)}...
                      </Text>
                    </Show>
                  </Radio>
                </MenuItem>
              )
            })}
          </Stack>
        </RadioGroup>
      </MenuList>
    </Menu>
  )
}
