import { BN, BN_TEN, BN_ZERO } from '@polkadot/util'
import BigNumber from 'bignumber.js'

export const getBalanceAmount = (amount: BN, decimal: number): BN => {
  if (amount.isZero()) {
    return BN_ZERO
  }
  const decimals = BN_TEN.pow(new BN(decimal))
  const balance = amount.div(decimals)
  return balance
}

export const getDisplayDecimalAmount = (
  amount?: BigNumber,
  decimal?: number
): string => {
  if (!amount || amount.isZero() || !decimal) {
    return '0'
  }

  const decimals = new BigNumber(10).pow(new BigNumber(decimal))
  const balance = amount.multipliedBy(decimals)
  return balance.toFixed(0, 1)
}

export function getDecimalAmount(amount: BigNumber, decimal: number): BigNumber
export function getDecimalAmount(amount: BN, decimal: number): BN
export function getDecimalAmount(
  amount: BigNumber | BN,
  decimal: number
): BigNumber | BN {
  if (amount instanceof BN) {
    if (amount.isZero()) {
      return BN_ZERO
    }

    const decimals = BN_TEN.pow(new BN(decimal))
    const balance = amount.mul(decimals)
    return balance
  }

  if (amount instanceof BigNumber) {
    if (amount.isZero()) {
      return BigNumber(0)
    }

    const decimals = BigNumber(10).pow(BigNumber(decimal))
    const balance = amount.multipliedBy(decimals)
    return balance
  }

  throw new Error(`Expected BN or BigNumber, but got '${amount}'.`)
}

export const toBigNumber = (num?: BN): BigNumber => {
  return new BigNumber(num?.toString() || '0')
}

export const toBN = (num: string | number, decimal?: number) => {
  const amount = new BigNumber(num)
  const decimals = new BigNumber(10).pow(decimal || 18)
  const balance = amount.times(decimals)
  return new BN(balance.toString())
}

/** user side amount (divided by decimal) **/
export const getTokenAmount = (
  amount?: BN | BigNumber,
  decimal?: number
): BigNumber => {
  if (!amount || amount.isZero()) {
    return new BigNumber(0)
  }
  const decimals = new BigNumber(10).pow(decimal || 18)
  if (amount instanceof BN) {
    const balance = toBigNumber(amount).dividedBy(decimals)
    return balance
  }

  const balance = amount.dividedBy(decimals)
  return balance
}

/** user side amount in string with significance (divided by decimal) **/
export const getDisplayBalanceAmount = (
  amount?: BN | BigNumber,
  decimal?: number,
  sd?: number
): string => {
  const result = getTokenAmount(amount, decimal)

  if (!sd) {
    return result.toFixed()
  }

  return toSignificantDigits(result, sd)
}

export const toSignificantDigits = (amount: BigNumber, sd: number) => {
 if (amount.gte(1)) {
   if (sd < 8) return Number(amount.toPrecision(sd, 1)).toString()
   return amount.toPrecision(sd, 1)
 } else {
   const decimals = new BigNumber(10).pow(sd)
   const limit = new BigNumber(1).div(decimals)

   if (amount.isZero()) {
    return '0'
   } else if (amount.gt(limit)) {
     return Number(amount.toPrecision(sd, 1)).toString()
   } else {
     return `< ${limit}`
   }
 }
}
