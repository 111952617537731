import { SelectNetwork } from '@/components/SelectNetwork'
import {
Flex,
FlexProps,
HStack,
IconButton,
Text
} from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import { useDomainEnv } from 'state/domain'
import { ConnectPolkadotJS } from '../ConnectPolkadotJS'

interface MobileProps extends FlexProps {
  onOpen: () => void
}
export const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { isApollo } = useDomainEnv()

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize={14}
        fontWeight="bold"
      >
        {isApollo ? 'Apollo Swap' : 'Arthswap'}
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <SelectNetwork />
        <ConnectPolkadotJS />
      </HStack>
    </Flex>
  )
}
