import { chainIdByName } from '@/constants/chainIdByName'
import { wsProvidersKey } from '@/constants/wsProviderInfo'
import { isProd } from '@/utils/env/getEnv'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Button,
  Image,
  Flex,
  Spacer
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useChainIdByProvider } from 'state/wsProvider'

interface Prop {}

interface PropTokenImage {
  name: string
  optionalText?: string
}

const MenuItemTokenImage: React.FC<PropTokenImage> = ({
  name,
  optionalText
}) => {
  return (
    <Flex>
      <Image src={`/tokens/${name}.webp`} alt={name} boxSize={5} />
      <Spacer ml={2} />
      {optionalText ? (
        <Text>{optionalText}</Text>
      ) : (
        <Text>{name}</Text>
      )}
    </Flex>
  )
}

export const SelectNetwork: React.FC<Prop> = () => {
  const [chainId, setChainId] = useChainIdByProvider()
  const selectedNetwork = useMemo(() => {
    switch (chainId) {
      case chainIdByName.shibuya:
        return 'Shibuya'
      case chainIdByName.shiden:
        return 'Shiden'
      default:
        return 'Select Network'
    }
  }, [chainId])

  const handleSelectNetwork = (network: wsProvidersKey) => {
    switch (network) {
      case 'shiden':
        setChainId(chainIdByName.shiden)
        break
      case 'shibuya':
        setChainId(chainIdByName.shibuya)
        break
      default:
        setChainId(chainIdByName.shibuya)
        break
    }
  }

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        <Flex>
          {selectedNetwork !== 'Select Network' ? (
            <MenuItemTokenImage name={selectedNetwork.toLowerCase()} />
          ) : (
            <Text>{selectedNetwork}</Text>
          )}
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleSelectNetwork('shibuya')}>
          <MenuItemTokenImage name="shibuya" optionalText="Shibuya (testnet)" />
        </MenuItem>
        <MenuItem onClick={() => handleSelectNetwork('shiden')} isDisabled={isProd}>
          <MenuItemTokenImage name="shiden" optionalText='Shiden (coming soon..)'/>
        </MenuItem>
        <MenuItem isDisabled={true}>
          <MenuItemTokenImage
            name="astar"
            optionalText="Astar (coming soon..)"
          />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
