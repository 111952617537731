import { chainIdByName } from '../chainIdByName'
import { ChainIdByTokens } from './types'

export const SHIBUYA_TOKENS_BY_SYMBOL = {
  ASTAR: {
    address: '5C4hrfjw9DjXZTzV3MwzrrAr9P1MJhSrvWGWqi1eSuyUpnhM',
    symbol: 'ASTR',
    name: 'Astar',
    decimal: 18,
    chainId: chainIdByName.shibuya,
    isNative: true
  },
  wASTAR: {
    address: 'ZCp71ZzxnEc7KcycQUdQv5VYNZMqnApKQsh1hwG2DTeEF1T',
    symbol: 'wASTR',
    name: 'wAstar',
    decimal: 18,
    chainId: chainIdByName.shibuya,
    isWrapped: true
  },
  APLO: {
    address: 'Yd2Z4Wri1uWMVPLxY5m3UDg7cejuornj7waJXi7Sq9cyMhC',
    symbol: 'APLO',
    name: 'Apollo',
    decimal: 18,
    chainId: chainIdByName.shibuya,
  },
  USDC: {
    address: 'YTcCssfLFyZpyHc3seFrj8vqJsuLw3B5NrGRQVdq2Yw7Ber',
    symbol: 'USDC',
    name: 'USDC',
    decimal: 6,
    chainId: chainIdByName.shibuya
  },
  USDT: {
    address: 'Wmiq6wtgomqBPhr1Vr4P61PR4JhhHfV15sQrqJZu94ww8vz',
    symbol: 'USDT',
    name: 'USDT',
    decimal: 6,
    chainId: chainIdByName.shibuya
  },
}

export const shibuyaTokens: ChainIdByTokens = {
  [chainIdByName.shibuya]:
    [
    {
      address: '5C4hrfjw9DjXZTzV3MwzrrAr9P1MJhSrvWGWqi1eSuyUpnhM',
      symbol: 'ASTR',
      name: 'Astar',
      decimal: 18,
      chainId: chainIdByName.shibuya,
      isNative: true
    },
    {
      address: 'ZCp71ZzxnEc7KcycQUdQv5VYNZMqnApKQsh1hwG2DTeEF1T',
      symbol: 'wASTR',
      name: 'wAstar',
      decimal: 18,
      chainId: chainIdByName.shibuya,
      isWrapped: true
    },
    {
      address: 'Yd2Z4Wri1uWMVPLxY5m3UDg7cejuornj7waJXi7Sq9cyMhC',
      symbol: 'APLO',
      name: 'Apollo',
      decimal: 18,
      chainId: chainIdByName.shibuya
    },
    {
      address: 'YTcCssfLFyZpyHc3seFrj8vqJsuLw3B5NrGRQVdq2Yw7Ber',
      symbol: 'USDC',
      name: 'USDC',
      decimal: 6,
      chainId: chainIdByName.shibuya
    },
    {
      address: 'Wmiq6wtgomqBPhr1Vr4P61PR4JhhHfV15sQrqJZu94ww8vz',
      symbol: 'USDT',
      name: 'USDT',
      decimal: 6,
      chainId: chainIdByName.shibuya
    }
  ]
}

export const shibuyaLPTokens: ChainIdByTokens = {
  [chainIdByName.shibuya]: [
    {
      address: 'Z6QTdZubAm8SA71XJb1x415e9Q9PtuGcTsgZ4WaVPnhZBUk',
      symbol: 'APLO/SBY',
      name: 'APLO/SBY-LP',
      decimal: 18,
      chainId: chainIdByName.shibuya
    },
    {
      address: 'bVZHeY66R45uVm4mi1Y9JBV719YBb7dsyKk1z6m4sWQ57C3',
      symbol: 'USDC/SBY',
      name: 'USDC/SBY-LP',
      decimal: 18,
      chainId: chainIdByName.shibuya
    },
    {
      address: 'YNcB5F4p6MbGwQyoUfysmDKD2GWsnp6YjBB73mBB2mG5HjH',
      symbol: 'USDT/SBY',
      name: 'USDT/SBY-LP',
      decimal: 18,
      chainId: chainIdByName.shibuya
    },
    {
      address: 'aBNQSpNjNcRwT6EKiV7Q9n845DuDW2ZthxZQ6DbXghKy41w',
      symbol: 'USDT/USDC',
      name: 'USDT/USDC-LP',
      decimal: 18,
      chainId: chainIdByName.shibuya
    },
    {
      address: 'XnZrYMMvJHfrsnYVVt6C9GUpqfNLhazG5CbUt7bVp6yeFhB',
      symbol: 'USDT/APLO',
      name: 'USDT/APLO-LP',
      decimal: 18,
      chainId: chainIdByName.shibuya
    },
    {
      address: 'WBRFed3pKnqHfHCgmhMnbekGSRjokDy4CkEEA45duEKsnF5',
      symbol: 'USDC/APLO',
      name: 'USDC/APLO-LP',
      decimal: 18,
      chainId: chainIdByName.shibuya
    }
  ]
}

export const shibuyaRewardTokens: ChainIdByTokens = {
  [chainIdByName.shibuya]: [
    {
      address: 'aUVz39CCarmJViLBRvuMHUgJyQHqqGwnKLutTCUZqNrWGfX',
      symbol: 'APLO',
      name: 'APLO',
      decimal: 18,
      chainId: chainIdByName.shibuya,
    }
  ]
}
