import React from 'react'
import {
  Box,
  CloseButton,
  Flex,
  BoxProps,
  VStack,
  Image
} from '@chakra-ui/react'
import { NavItem } from '../NavItem'
import { SidebarFooter } from '../SidebarFooter'
import { isProd } from '@/utils/env/getEnv'
import { useDomainEnv } from 'state/domain'

interface SidebarProps extends BoxProps {
  onClose: () => void
}

interface LinkItemProps {
  name: string
  icon: string
  to: string
  enableOnProd: boolean
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Swap', icon: '/Swap.svg', to: '/swap', enableOnProd: true },
  { name: 'Pool', icon: '/Pool.svg', to: '/pool', enableOnProd: true },
  { name: 'Farm', icon: '/Farm.svg', to: '/farm', enableOnProd: true },
  { name: 'Faucet', icon: '/Swap.svg', to: '/faucet', enableOnProd: true }
]

export const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { isApollo } = useDomainEnv()

  const linkItems = isProd
    ? LinkItems.filter((item) => item.enableOnProd)
    : LinkItems

  return (
    <Box
      w={{ base: 'full', md: '220px' }}
      pos="fixed"
      h="100vh"
      bg={isApollo ? 'whiteAlpha.50' : 'transparent'}
      {...rest}
    >
      <VStack align="stretch" justify="space-between" h="full">
        <Box>
          <Flex
            h="20"
            alignItems="center"
            mx="8"
            justifyContent="space-between"
          >
            <Image
              src={isApollo ? '/logo_wh.png' : '/arthswap/arthswap_logo.png'}
              alt={isApollo ? 'ApolloSwap' : 'Arthswap'}
              width={'157.3px'}
              height={'40px'}
            />
            <CloseButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onClose}
            />
          </Flex>
          {linkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} to={link.to}>
              {link.name}
            </NavItem>
          ))}
        </Box>
        <SidebarFooter />
      </VStack>
    </Box>
  )
}
