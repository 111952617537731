import { shibuyaTokens, shibuyaLPTokens, shibuyaRewardTokens } from './shibuya';
import { shidenLPTokens, shidenRewardTokens, shidenTokens } from './shiden';
import { ChainIdByTokens, Token } from './types';

export const tokens: ChainIdByTokens = {
  ...shibuyaTokens,
  ...shidenTokens,
}

export const lpTokens: ChainIdByTokens = {
  ...shibuyaLPTokens,
  ...shidenLPTokens,
}

export const rewardTokens: ChainIdByTokens = {
  ...shibuyaRewardTokens,
  ...shidenRewardTokens
}

export const createToken = (address: string, symbol: string, decimal: number): Token => {
  return {
    address,
    symbol,
    name: symbol,
    decimal,
    chainId: 0,
    isNative: false
  }
}
