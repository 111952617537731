import * as Sentry from '@sentry/browser'
import { isProd } from './getEnv'

let showLog: string | null = null

if (typeof window !== 'undefined') {
  showLog = localStorage.getItem('CONFIG-SHOW-LOG')
}

export const sentryLogError = (error: unknown) => {
  if (!isProd) return
  if (showLog) console.error(error)
  Sentry.captureException(error)
}
