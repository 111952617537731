import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

export const initialDomainEnv = atom<{
  isApollo: boolean
  isChecking: boolean
}>({
  key: 'domainEnv',
  default: {
    isApollo: true,
    isChecking: true
  }
})

type Result = {
  isApollo: boolean
  isChecking: boolean
}

export const useDomainEnv = (): Result => {
  const [domainEnv, setDomainENV] = useRecoilState(initialDomainEnv)

  useEffect(() => {
    const isArthswap = window.location.hostname === 'wasm.arthswap.org'
    setDomainENV({ isApollo: !isArthswap, isChecking: false })
  }, [setDomainENV])

  return domainEnv
}
