import React, { ReactText } from 'react'
import { Flex, FlexProps, Image } from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface NavItemProps extends FlexProps {
  icon: any
  children: ReactText
  to: string
}

export const NavItem = ({ icon, to, children, ...rest }: NavItemProps) => {
  const router = useRouter()

  const isActiveRoute = router.pathname.startsWith(to)

  return (
    <Link href={to} style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        gap="1rem"
        px="4"
        py="3"
        mx="4"
        cursor="pointer"
        _hover={{
          bg: 'whiteAlpha.100'
        }}
        bg={isActiveRoute ? 'whiteAlpha.300' : 'inherit'}
        fontSize={{ base: 14, lg: 16 }}
        {...rest}
      >
        {icon && <Image src={icon} alt="icon" className="logo" />}
        {children}
      </Flex>
    </Link>
  )
}
