import Head from 'next/head'
import Script from 'next/script'

const ArthswapHead = () => {
  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          type="image/png"
          href="/arthswap/favicon.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="/arthswap/192x192_App_Icon.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="512x512"
          href="/arthswap/512x512_App_Icon.png"
        />
        <link rel="manifest" href="/arthswap/manifest.json" />

        <title>ArthSwap | the No.1 one-stop DeFi / DEX on Astar Network</title>
        <meta
          name="description"
          content="ArthSwap is the No.1 decentralized exchange with the largest community on Astar Network. It has very high APY and simple UI,  quite easy to use."
        />
        <meta name="theme-color" content="#000000" />
        <meta name="fortmatic-site-verification" content="j93LgcVZk79qcgyo" />
        <meta
          name="viewport"
          content="initial-scale=1, maximum-scale=3, minimum-scale=1, user-scalable=no"
        />
        <meta name="twitter:image" content="/arthswap/arthswap.png" />
        <meta
          name="twitter:description"
          content="ArthSwap is the No.1 decentralized exchange with the largest community on Astar Network. It has very high APY and simple UI,  quite easy to use."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="ArthSwap is the No.1 decentralized exchange with the largest community on Astar Network. It has very high APY and simple UI,  quite easy to use."
        />
      </Head>
      <Script id="google-tag-manager">
        {`;(function(w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', '%REACT_APP_GTAG%')`}
      </Script>
    </>
  )
}

export default ArthswapHead
