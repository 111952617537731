import { useChainId } from '@/hooks/useApi'
import { Image, ImageProps } from '@chakra-ui/react'

interface Prop extends ImageProps {
  address: string
  ml?: number
}

export const tokenImage = (chainId: number, address: string) => {
  return `/tokens/${chainId}/${address}.png`
}

export const TokenImage: React.FC<Prop> = ({ address, ml, ...rest }) => {
  const chainID = useChainId()

  if (!chainID) {
    return <></>
  }

  return (
    <Image
      src={tokenImage(chainID, address)}
      alt={address.substring(0, 2)}
      boxSize={'32px'}
      ml={ml ? ml : 0}
      {...rest}
    />
  )
}
