import React from 'react'
import { Box, HStack, Text, Image } from '@chakra-ui/react'
import { useDomainEnv } from 'state/domain'

interface LinkItemProps {
  name: string
  icon: string
  to: string
}

enum DomainEnvs {
  APOLLO = 'apollo',
  ARTHSWAP = 'arthswap'
}

const LinkItems: { [key in DomainEnvs]: Array<LinkItemProps> } = {
  [DomainEnvs.APOLLO]: [
    {
      name: 'twitter',
      icon: '/Twitter.svg',
      to: 'https://twitter.com/Apollo_dex'
    },
    {
      name: 'Medium',
      icon: '/Medium.svg',
      to: 'https://medium.com/@apolloswap'
    },
    {
      name: 'discord',
      icon: '/Discord.svg',
      to: 'https://discord.gg/3Hq6xUyjw8'
    },
    {
      name: 'Faucet',
      icon: '/Github.svg',
      to: 'https://github.com/AstarNetwork/wasm-showcase-dapps/tree/main/uniswap-v2'
    }
  ],
  [DomainEnvs.ARTHSWAP]: [
    {
      name: 'twitter',
      icon: '/Twitter.svg',
      to: 'https://twitter.com/arthswap'
    },
    {
      name: 'Medium',
      icon: '/Medium.svg',
      to: 'https://medium.com/arthswap'
    },
    {
      name: 'discord',
      icon: '/Discord.svg',
      to: 'https://discord.gg/GyHZ69rPTK'
    },
    {
      name: 'Github',
      icon: '/Github.svg',
      to: 'https://github.com/ArthSwap'
    }
  ]
}

export const SidebarFooter = () => {
  const { isApollo } = useDomainEnv()
  const key = isApollo ? DomainEnvs.APOLLO : DomainEnvs.ARTHSWAP

  return (
    <Box p={4} fontSize={{ base: 14, lg: 16 }}>
      <HStack mt="20">
        {LinkItems[key].map((item, i) => (
          <a key={i} href={item.to} target="_blank" rel="noopener noreferrer">
            <Image src={item.icon} alt={item.name} className="logo" />
          </a>
        ))}
      </HStack>
      <Box height="50"></Box>
      <Text>&#169; 2023 {isApollo ? 'Apollo Swap' : 'Arthswap'}</Text>
    </Box>
  )
}
