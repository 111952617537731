import React, { ReactNode } from 'react'
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react'

import { SidebarContent } from './SidebarContent'
import { MobileNav } from './MobileNav'
import { useDomainEnv } from 'state/domain'
import ArthswapHead from './ArthswapHead'

export const Sidebar = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isApollo, isChecking } = useDomainEnv()

  if (isChecking) {
    return null
  }

  return (
    <>
      {!isApollo && <ArthswapHead />}
      <Box
        minH="100vh"
        bg={!isApollo ? 'url(\'/arthswap/arthswap_bg.png\')' : 'none'}
        bgSize="cover"
      >
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4" px="2">
          {children}
        </Box>
      </Box>
    </>
  )
}
