import { useChainId } from '../useApi'
import { tokens } from '@/constants/tokens'
import { Token } from '@/constants/tokens/types'

export const useAllTokens = (): Token[] | [] => {
  const chainId = useChainId()
  if (!chainId) {
    return []
  }
  return tokens[chainId] || []
}
