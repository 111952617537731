import { chainIdByName } from '../chainIdByName'
import { ChainIdByTokens } from './types'

export const SHIDEN_TOKENS_BY_SYMBOL = {
  ASTAR: {
    address: '5C4hrfjw9DjXZTzV3MwzrrAr9P1MJhSrvWGWqi1eSuyUpnhM',
    symbol: 'SDN',
    name: 'SDN',
    decimal: 18,
    chainId: chainIdByName.shiden,
    isNative: true
  },
  wSDN: {
    address: 'ZSDrDTGMNimo22YHpzs49fzG4GcerPrpYQzoEtpySrtSRpo',
    symbol: 'wSDN',
    name: 'wSDN',
    decimal: 18,
    chainId: chainIdByName.shiden,
    isWrapped: true
  },
  APLO: {
    address: 'b8fqotA8AckKtR6wfmE7QYZ6y5w8Hsh1MN2wAuXEQ1jbfVu',
    symbol: 'APLO',
    name: 'Apollo',
    decimal: 18,
    chainId: chainIdByName.shiden,
  },
  USDC: {
    address: 'Z7NpKVgBavzEQRRNyNmHi5U1BBZjLH1XpJydao8vFH4K8xn',
    symbol: 'USDC',
    name: 'USDC',
    decimal: 6,
    chainId: chainIdByName.shiden,
  },
  USDT: {
    address: 'XCpKjSRxEbAS8LDcQZ7ywAmrcUjqaimbi24q3mwAhcthfJq',
    symbol: 'USDT',
    name: 'USDT',
    decimal: 6,
    chainId: chainIdByName.shiden,
  },
} as const

export const shidenTokens: ChainIdByTokens = {
  [chainIdByName.shiden]:
    [
      ...Object.values(SHIDEN_TOKENS_BY_SYMBOL)
    ]
}

export const shidenLPTokens: ChainIdByTokens = {
  [chainIdByName.shiden]:
    [
      {
        address: 'ZecCT7BaZrXYxZrPJypNq3A57PnQACZJwzjPnG9iyPmUe5s',
        symbol: 'APLO/SDY',
        name: 'APLO/SDY-LP',
        decimal: 18,
        chainId: chainIdByName.shiden,
      },
      {
        address: 'ayNfpxDUZazkrkXhexvKTW6BHBquUztEEZJRH9qT7hrJtyc',
        symbol: 'USDC/SDY',
        name: 'USDC/SDY-LP',
        decimal: 18,
        chainId: chainIdByName.shiden,
      },
      {
        address: 'WPmuWw7EAdHZM8GvEAvYVFdgRyFj7FY3jcMLRgBB9SeuWtj',
        symbol: 'USDT/SDY',
        name: 'USDT/SDY-LP',
        decimal: 18,
        chainId: chainIdByName.shiden
      }
    ]
}

export const shidenRewardTokens: ChainIdByTokens = {
  [chainIdByName.shiden]: [
    SHIDEN_TOKENS_BY_SYMBOL.APLO
  ]
}
